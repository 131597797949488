<template>
  <div id="changePassword">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ $t('changePw.header') }}</h2>
          <p></p>
        </div>
        <div class="meta_box" v-if="formFlag">
          <div class="changePassword_box">
            <div class="change_form">
              <el-form :model="ruleChangePasswordForm" :rules="rulesChangePassword" ref="ruleChangePasswordForm">
                <InputForm
                  :label="$t('common.field.currPw')"
                  type="password"
                  name="currentPass"
                  v-model.trim="ruleChangePasswordForm.currentPass"
                ></InputForm>

                <InputForm
                  :label="$t('common.field.newPw')"
                  class="password_error"
                  type="password"
                  name="pass"
                  v-model.trim="ruleChangePasswordForm.pass"
                ></InputForm>

                <InputForm
                  class="newPassword"
                  :label="$t('common.field.confirmPw')"
                  type="password"
                  name="checkPass"
                  v-model.trim="ruleChangePasswordForm.checkPass"
                ></InputForm>

                <VerificationCode
                  v-if="verificationEnable"
                  ref="verificationCode"
                  namespace="CP_UPDATE_PASSWORD"
                ></VerificationCode>

                <div class="btn_item">
                  <el-button class="purple_button" @click="submitForm('ruleChangePasswordForm')" data-testid="submit">
                    {{ $t('common.button.submit') }}
                  </el-button>
                </div>
              </el-form>
            </div>
          </div>
        </div>
        <Result v-else>
          <p v-html="$t('changePw.succ')"></p>
        </Result>
      </div>
    </div>
  </div>
</template>

<script>
import { md5 } from '@/util/encrypt';
import { encrypt } from '@/util/rsa/JSEncrypt.js';
import Result from '@/components/Result';
import VerificationCode from '@/components/withdraw/VerificationCode.vue';
import { apiCheckPassword, apiUpdatePassword, apiCheckMfaSwitchd, apiEncryptPublicKey } from '@/resource';

export default {
  name: 'changePassword',
  components: { Result, VerificationCode },
  data() {
    const validateCurrentPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.currPwReq')));
      } else {
        apiCheckPassword({
          currentPass: md5(value)
        }).then(resp => {
          if (!resp.data) {
            callback(new Error(this.$t('common.formValidation.currPwWrong')));
          } else {
            callback();
          }
        });
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*.()])(?=.*[0-9])(?=.*[a-z]).{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('common.formValidation.newPwFormat')));
        } else {
          apiCheckPassword({
            currentPass: md5(value)
          }).then(resp => {
            if (resp.data) {
              callback(new Error(this.$t('common.formValidation.IdenficalPw')));
            } else {
              callback();
            }
          });
        }
      }
    };
    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.ruleChangePasswordForm.pass) {
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      }
      callback();
    };
    return {
      formFlag: true,
      verificationEnable: false,
      ruleChangePasswordForm: {
        currentPass: '',
        pass: '',
        checkPass: ''
      },
      rulesChangePassword: {
        currentPass: [{ required: true, validator: validateCurrentPass, trigger: 'blur' }],
        pass: [{ required: true, validator: validatePass, trigger: 'blur' }],
        checkPass: [{ required: true, validator: validateCheckPass, trigger: 'blur' }]
      }
    };
  },
  methods: {
    getValidationComponent() {
      const cp = [this.$refs.ruleChangePasswordForm.validate()];
      if (this.verificationEnable) {
        cp.push(this.$refs.verificationCode.submitForm());
      }
      return cp;
    },
    getFormsValidation() {
      return Promise.all(this.getValidationComponent())
        .then(valid => {
          return Promise.resolve(true);
        })
        .catch(invalid => {
          return Promise.resolve(false);
        });
    },
    submitForm() {
      this.getFormsValidation().then(valid => {
        if (valid) this.updatePassword();
        else return false;
      });
    },
    checkMfaSwitchd() {
      apiCheckMfaSwitchd().then(resp => {
        this.verificationEnable = resp.data;
      });
    },
    async updatePassword() {
      const getVerifyCode = this.verificationEnable ? this.$refs.verificationCode.getFormDataOfChild() : {};
      let result = await apiEncryptPublicKey();
      let publicKey = result.data.data;
      apiUpdatePassword(
        {
          currentPass: md5(this.ruleChangePasswordForm.currentPass),
          pass: encrypt(this.ruleChangePasswordForm.pass, publicKey),
          checkPass: encrypt(this.ruleChangePasswordForm.checkPass, publicKey),
          ...getVerifyCode
        },
        publicKey
      ).then(resp => {
        if (resp.data.code === 0) this.formFlag = false;
      });
    }
  },
  mounted() {
    this.checkMfaSwitchd();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/forgetChangePasswordReq.scss';
</style>
